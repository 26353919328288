import { IconArrowRight } from '../../../assets/icons/buttons/IconArrowRight';
import { clearTel } from '../helpers/clearTel';
import { IconPhone } from '../../../assets/icons/buttons/IconPhone';
import { IconMail } from '../../../assets/icons/buttons/IconMail';
import React from 'react';
import { CustomLink } from '../../general/CustomLink/CustomLink';

export const OurPeopleContactCard = ({
  itemId,
  img,
  nome,
  cargo,
  description,
  comentario,
  ourPeople,
  email,
  isEditMode,
  tel,
  linkText,
  linkURL,
}) => {
  /** @param {React.MouseEvent<HTMLAnchorElement, MouseEvent>} ev*/
  const preventDefaultEditable = (ev) => {
    if (isEditMode) ev.preventDefault();
  };

  return (
    <div
      key={itemId}
      className="flex flex-col gap-[21px] justify-between h-full w-full p-4 bg-[#F2F4F4] dark:bg-[#001C3A] hover:bg-[#e7ebeb] dark:hover:bg-[#003063] transition duration-300 rounded-sm"
    >
      <div className="flex flex-col ">
        <img
          className="w-[102px] h-[102px] object-cover mx-auto rounded-full mb-[21px]"
          src={img}
        />
        <h1 className="dark:text-[#F8FAFA] font-dsSubtitle font-bold text-dsGray-900">
          {nome}
        </h1>
        <p className="dark:text-[#F8FAFA] font-dsCaption tracking-[.07em] leading-6 text-left uppercase text-dsGray-900">
          {cargo}
        </p>
        <p className="dark:text-[#ffffff] leading-[20px] text-[14px] text-dsGray-900 overflow-y-auto">
          {comentario}
        </p>
      </div>
      <div className="flex flex-col gap-2">
        {tel ? (
          <a
            onClick={preventDefaultEditable}
            href={`tel:${clearTel(tel)}`}
            className="flex flex-row gap-2 w-fit text-[#0169CD] group-hover:text-[#FCBC00] dark:group-hover:text-[#FCBC00] dark:hover:text-[#FCBC00] dark:text-[#A9C7FF] title_small-semibold group"
          >
            <IconPhone
              size="22"
              className={
                'dark:[&_path]:stroke-[#A9C7FF] group-hover:[&_path]:stroke-[#FCBC00] dark:group-hover:[&_path]:stroke-[#FCBC00]'
              }
            ></IconPhone>
            <span className="text-sm leading-none my-auto">{tel}</span>
          </a>
        ) : (
          <div className="h-[22px]"></div>
        )}
        {email ? (
          <a
            onClick={preventDefaultEditable}
            href={`mailto:${email}`}
            className="flex flex-row gap-2 w-fit text-[#0169CD] group-hover:text-[#FCBC00] dark:group-hover:text-[#FCBC00] dark:hover:text-[#FCBC00] dark:text-[#A9C7FF] title_small-semibold group"
          >
            <div className="w-[22px] aspect-square dark:[&_path]:stroke-[#A9C7FF] group-hover:[&_path]:stroke-[#FCBC00] dark:group-hover:[&_path]:stroke-[#FCBC00">
              <IconMail className={''} size="22"></IconMail>
            </div>
            <span className="text-sm leading-none my-auto">{email}</span>
          </a>
        ) : (
          <div className="h-[22px]"></div>
        )}

        {linkURL ? (
          <CustomLink
            isEditMode={isEditMode}
            href={linkURL}
            className="flex flex-row gap-2 w-fit text-[#0169CD] dark:group-hover:text-[#FCBC00] group-hover:text-[#FCBC00] dark:text-[#A9C7FF] title_small-semibold group"
          >
            <span className="text-sm leading-none my-auto">{linkText}</span>
            <IconArrowRight
              size="22"
              className="dark:[&_path]:stroke-[#A9C7FF] dark:group-hover:[&_path]:stroke-[#FCBC00] group-hover:[&_path]:stroke-[#FCBC00]"
            ></IconArrowRight>
          </CustomLink>
        ) : (
          <div className="h-[22px]"></div>
        )}
      </div>
    </div>
  );
};
