import { twMerge } from 'tailwind-merge';
import { NavLink } from 'react-router-dom';
import '../styles.css';

export const HeaderDesktop = ({ searchOpen }) => {
  return (
    <ul
      id="navContainer"
      className="list-none hidden md:flex gap-x-2 justify-between items-start mt-4 dark:[&_ul]:bg-[#003063]"
    >
      <li
        className={`cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <span
          id="ensino"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
        >
          Ensino
        </span>
        <ul className="text-base lg:text-lg list-none lista hidden group-hover:flex absolute top-full left-0 flex-col z-10 bg-dsGray-100 dark:[&_a]:text-[#F8FAFA]">
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Estude na UTFPR"
              to="/cursos/estudenautfpr"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Estude na UTFPR
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Graduação"
              to="/cursos/graduacao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Graduação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Pós-graduação"
              to="/pesquisa-e-pos-graduacao/cursos"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Pós-graduação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Programas de Pós-Graduação"
              to="/cursos/mestrado-e-doutorado"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Programas de Pós-Graduação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Educação a distância"
              to="/cursos/educacao-a-distancia"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Educação a distância
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Outros cursos"
              to="/cursos/outros"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Outros cursos
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className={`cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <span
          id="pesquisa"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
        >
          Pesquisa
        </span>
        <ul className="dark:[&_a]:text-[#F8FAFA] text-base lg:text-lg list-none lista text-dsGray-900 absolute hidden group-hover:flex top-full left-0 flex-col z-10 bg-dsGray-100">
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Iniciação Científica"
              to="/pesquisa-e-pos-graduacao/bolsas/pic"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Iniciação Científica
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Mestrado e Doutorado"
              to="/pesquisa-e-pos-graduacao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Mestrado e Doutorado
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Grupos de Pesquisa"
              to="/pesquisa-e-pos-graduacao/grupos-de-pesquisa"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Grupos de Pesquisa
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Laboratórios"
              to="/pesquisa-e-pos-graduacao/laboratorios-multiusuarios"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Laboratórios
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <span
          id="inovacao"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
        >
          Inovação
        </span>
        <ul className="dark:[&_a]:text-[#F8FAFA] text-base lg:text-lg list-none lista text-dsGray-900 absolute hidden group-hover:flex top-full left-0 flex-col z-10 bg-dsGray-100">
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Agência de Inovação"
              to="/inovacao/agencia"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Agência de Inovação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Política de Inovação"
              to="/inovacao/politica-de-inovacao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Política de Inovação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Empreendedorismo"
              to="/inovacao/empreendedorismo"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Empreendedorismo
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Incubadora"
              to="/inovacao/empreendedorismo/incubadora-de-inovacoes-da-utfpr"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Incubadora
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Parque tecnológico"
              to="/inovacao/parque-tecnologico"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Parque tecnológico
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Empresa Júnior"
              to="/inovacao/empreendedorismo/empresa-junior"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Empresa Júnior
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Vitrine Tecnológica"
              to="/inovacao/vitrine-tecnologica"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Vitrine Tecnológica
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <span
          id="extensao"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
        >
          Extensão
        </span>
        <ul className="dark:[&_a]:text-[#F8FAFA] text-base lg:text-lg list-none lista text-dsGray-900 absolute hidden group-hover:flex top-full left-0 flex-col z-10 bg-dsGray-100">
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Cursos Livres"
              to="/cursos/outros/extensao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Cursos Livres
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Cultura"
              to="/cultura"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Cultura
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Ações Sociais"
              to="/"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Ações Sociais
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Extensão"
              to="/extensao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Extensão
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Voluntariado"
              to="/"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Voluntariado
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <span
          id="institucional"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
        >
          Institucional
        </span>
        <ul className="dark:[&_a]:text-[#F8FAFA] text-base lg:text-lg list-none lista text-dsGray-900 absolute hidden group-hover:flex top-full left-0 flex-col z-10 bg-dsGray-100">
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Sobre a UTFPR"
              to="/institucional"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Sobre a UTFPR
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Quem é quem"
              to="/institucional/quem-e-quem"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Quem é quem
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Estrutura"
              to="/estrutura"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Estrutura
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Documentos"
              to="/documentos"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Documentos
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Editais"
              to="/editais"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Editais
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Comunicação"
              to="/comunicacao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Comunicação
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Comissões"
              to="/comissoes"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Comissões
            </NavLink>
          </li>
          <li className="hover:font-bold hover:bg-dsYellow-50 border-l-[3px] border-transparent hover:border-dsYellow-400 dark:hover:bg-[#00468C]">
            <NavLink
              title="Avaliação Institucional"
              to="/servicos-a-comunidade/avaliacao"
              className="text-dsGray-900 hover:text-dsGray-900 inline-block h-full w-full px-5 py-3"
            >
              Avaliação Institucional
            </NavLink>
          </li>
        </ul>
      </li>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <NavLink
          id="informacao"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
          to="/acesso-a-informacao"
        >
          Acesso à informação
        </NavLink>
      </li>
      <li
        className={`h-full cursor-pointer whitespace-nowrap group relative ${
          searchOpen ? 'bottomWhiteBorder' : 'bottomYellowBorder'
        }`}
      >
        <NavLink
          id="prestacao"
          className={twMerge(
            'text-base font-bold lg:text-xl text-[#0D1117] hover:text-[#0D1117] dark:text-[#F8FAFA]',
            searchOpen && 'dark:text-[#0D1117]',
          )}
          to="/transparencia/transparencia-e-prestacao-de-contas"
        >
          Prestação de contas
        </NavLink>
      </li>
    </ul>
  );
};
