// @ts-check
import React, { useState, useEffect } from 'react';
import { FiClock } from 'react-icons/fi';
import { ImagePlaceholder } from '../../../../assets/icons/buttons/ImagePlaceholder';
import Chip from '../../../general/Chip/Chip';
import Ping from '../../../general/Ping/Ping';

import { getPage } from '../../../../helpers/getPage';
import { getSubjectFromPage } from '../../../../helpers/getSubjectFromPage';
import { CustomLink } from '../../../general/CustomLink/CustomLink';

const MainNewsItemRow = ({
  img,
  tags,
  title,
  link,
  youtube,
  youtubeState,
  description,
  date,
  editable,
  showImage,
}) => {
  const [imageIsVisible, setImageIsVisible] = useState(true);

  const [hat, setHat] = useState('');

  useEffect(() => {
    async function getHat() {
      const page = await getPage(link);

      if (!page) return;

      const subject = await getSubjectFromPage(page);

      setHat(subject);
    }
    getHat();
  });

  return (
    <CustomLink
      className={`group w-full h-full flex flex-col justify-start items-start cursor-pointer bg-white hover:bg-[#f5f5f5] dark:bg-[#001C3A] dark:hover:bg-[#003063] transition duration-300 shadow-md hover:shadow-lg rounded overflow-hidden`}
      href={link}
      isEditMode={editable}
    >
      {showImage ? (
        <div
          className={`w-full overflow-clip ${
            youtube ? '!h-[180px] !min-h-[180px]' : '!h-[270px]'
          }`}
        >
          {imageIsVisible ? (
            <img
              src={img}
              alt={title}
              loading="lazy"
              className="w-full object-cover h-[270px] group-hover:scale-110 transition duration-300"
              onError={() => setImageIsVisible(false)}
            />
          ) : (
            <div className="group w-full h-full flex justify-center items-center">
              <ImagePlaceholder
                className="h-[270px] dark:[&_path]:stroke-[#c1c1c1] group-hover:[&_path]:stroke-[#005DB7]"
                size="35"
                color="#000000"
              />
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
      <div className="flex flex-col p-6 w-full h-full gap-y-2 md:gap-y-3">
        {hat && (
          <h5 className="font-semibold text-[#2E3132] text-[13px] uppercase dark:text-[#F8FAFA] line-clamp-1 md:text-base">
            {hat}
          </h5>
        )}
        <h1 className="text-[17px] md:text-[20px] font-bold line-clamp-2 lg:line-clamp-3 leading-7 text-dsGray-900 dark:text-[#F8FAFA] group-hover:text-[#005DB7] group-hover:dark:text-[#F8FAFA]">
          {title}
        </h1>
        <div className="flex justify-start items-center gap-2 text-[#8D9199]">
          <FiClock className="text-[15px]" />
          <span className="text-[14px]">{date}</span>
        </div>
        <p className="text-[13px] text-left leading-[16px] md:pt-1 md:text-base text-[#0D1117] dark:text-[#F8FAFA] line-clamp-2">
          {description}
        </p>
        <div className="flex justify-start items-start gap-2 pt-1 mt-auto flex-wrap">
          {tags ? (
            tags.map((tag, index) => {
              //@ts-ignore
              return <Chip key={index} text={tag} />;
            })
          ) : (
            <></>
          )}
          {youtube && youtubeState && youtubeState !== 'none' ? (
            //@ts-ignore
            <Chip>
              <Ping />
              {youtubeState === 'upcoming' && 'Em breve'}
              {youtubeState === 'live' && 'Ao vivo'}
            </Chip>
          ) : (
            <></>
          )}
        </div>
      </div>
    </CustomLink>
  );
};

export default MainNewsItemRow;
