import React from 'react';
import { assertBaseDataTypes } from './assertBaseDataTypes';
import { CardView as CompactCardView } from './layout/Compact/CardView';
import { CardView as CompleteCardView } from './layout/Complete/CardView';
import { CardsCarouselContainer } from './CardsCarouselContainer';
import { SwiperSlide } from 'swiper/react';
import { Layouts } from './layout/layouts';
import { ListingContainer } from './ListingContainer';

export const ExtensionProjectCardsView = (props) => {
  const data = assertBaseDataTypes(props.data);
  return (
    <>
      {data.layout == Layouts.COMPACT ? (
        <CardsCarouselContainer>
          {data.cards.map((card, idx) => (
            <SwiperSlide key={idx} className="!h-auto pb-4">
              <CompactCardView data={card} key={idx}></CompactCardView>
            </SwiperSlide>
          ))}
        </CardsCarouselContainer>
      ) : (
        <ListingContainer>
          {data.cards.map((card, idx) => (
            <CompleteCardView data={card} key={idx}></CompleteCardView>
          ))}
        </ListingContainer>
      )}
    </>
  );
};
