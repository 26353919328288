import React, { useState, useEffect } from 'react';

const Slider = (props)=>{

    const [queue, setQueue] = useState(null);

    const constructQueue = ()=>{
        let arr = [];
        for(let i = 1; i <= props.pagesNumber; i++){
            let classes = "w-[7px] h-[7px] text-[#005DB7] rounded-full "
            if(props.activePage == i){
                classes += "bg-[#005DB7]";
            }
            else{
                classes += "bg-[#DAD8D8]";
            }
            arr.push(<div key={i} className={classes}></div>)

        }
        setQueue(arr);
    }

    useEffect(()=>{
        constructQueue();
    },[props])

    return(<>
        {queue}
    </>)
}

export default Slider;
